@use './assets/scss/utils/global-classes' as *;
@use '@angular/material' as mat;
@import "reset";
@import "typography";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "primeicons/primeicons.css";



[lang="en"] {
	$open-sans-typography: mat.define-typography-config($font-family: "'Basic-Sans', sans-serif");
	@include mat.typography-hierarchy($open-sans-typography);
	
	$app-theme: mat.define-light-theme((typography: $open-sans-typography));
	
	@include mat.all-component-themes($app-theme);

}

[lang="ar"] {
	$open-sans-typography: mat.define-typography-config($font-family: "'Cairo', sans-serif");
	@include mat.typography-hierarchy($open-sans-typography);
	
	$app-theme: mat.define-light-theme((typography: $open-sans-typography));
	
	@include mat.all-component-themes($app-theme);
}




body {
	* {
		// border: 1px solid red;
	}
}

[lang="en"] {
	body {
		font-family: "Basic-Sans", sans-serif !important;
		;
	}
}

[lang="ar"] {
	body {
		font-family: "Cairo", sans-serif !important;
	}
}


.important-underline {
	text-decoration: underline !important;
}

input::placeholder {
	opacity: 0.4;
}

textarea {
	resize: none !important;
}

.scrollbar-hide::-webkit-scrollbar {
	display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}


.upload-area {
	position: relative;
	/* Ensure the upload area is relatively positioned */
}

.upload-area ngx-spinner {
	position: absolute;
	/* Position spinner absolutely within the container */
	top: 50%;
	/* Center horizontally */
	left: 50%;
	/* Center vertically */
	transform: translate(-50%, -50%);
	/* Offset by half its width/height */
}

/* Customize PrimeNG Tooltip Background */
.p-tooltip {
	background-color: rgb(38 47 59 / var(--tw-bg-opacity));
	/* Change to your desired background color */
	color: white;
	/* Change the text color */
	border-radius: 4px;
	/* Optional: round corners */
	padding: 10px;
	/* Optional: adjust padding */
	font-size: 12px;
}

@media (max-width: 768px) {

	input,
	textarea,
	select {
		font-size: 12px;
	}
}





:root {
	--mat-mdc-typography-font-family: Cairo, sans-serif;
	--mat-stepper-container-text-font: Cairo, sans-serif;
	--mat-stepper-label-font: Cairo, sans-serif;
	--mat-button-text-font: Cairo, sans-serif;
	/* Add more overrides if needed */
  }